<template>
  <div class="list-buttons">
    <div class="btn-group-vertical">
      <button
        v-for="item, index in items"
        :key="index"
        @click="onClick(index)"
        type="button"
        class="btn btn-outline-dark"
        v-bind:class="{checked: checked == index}"
      >{{item.name}}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListButtons',
  props: {
    items: {
      type: Array,
      required: true,
    },
    checked: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    onClick(index) {
      this.$emit('click', index);
    },
  },
}
</script>

<style scoped>
.checked {
  background-color: #0c7489;
  border-color: #0b6274;
  color: #fff;
}
button, .btn-group-vertical {
  width: 100%;
}
</style>