import Vue from 'vue';
import VueSocketIO from 'vue-socket.io';
import io from 'socket.io-client';

// const socket = io('http://localhost:3000');
const socket = io();

Vue.use(new VueSocketIO({
  debug: false,
  connection: socket
}));