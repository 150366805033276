<template>
  <div class="alert-card media">
    <!-- <img src="@/assets/logo.png" width="80" height="80" alt="picture"> -->
    <div class="media-body container-fluid pt-1 pb-1">
      <div class="row">
        <div class="col mt-2 mb-2">
          <span><b>Nombre: </b>{{alert.name}}</span> <br>
          <span v-if="role>=2"><b>Distancia: </b>{{getDist(alert.latitude, alert.longitude)}} km</span>
          <span v-if="role<2"><b>Coordenadas: </b>{{alert.latitude}}, {{alert.longitude}}</span>
          <span v-if="alert.attendedBy != null && showStatus"><br><b>En proceso</b></span>
        </div>
        <div class="col col-sm-auto buttons-container">
          <span class="text-muted">
            Hace {{timeElapsed(alert.date)}}
          </span> <br>
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-outline-info" @click="openMapModal(index)">Ver en el mapa</button>
            <button type="button" class="btn btn-primary" v-bind:disabled="alert.attendedBy != null" @click="goToAlertDetails(alert)">{{textGoToAlertDetails}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlertCard',
  props: {
    alert: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    openMapModal: {
      type: Function,
      required: true
    },
    goToAlertDetails: {
      type: Function,
      required: true
    },
    showStatus: {
      type: Boolean,
      default: false
    },
    textGoToAlertDetails: {
      type: String,
      default: 'Atender alerta'
    }
  },
  data() {
    return {
      role: parseInt(localStorage.getItem('role')),
    }
  },
  methods: {
    timeElapsed (date) {
      const seconds = Math.floor((new Date() - new Date(date)) / 1000)
      let interval = Math.floor(seconds / 31536000)
      if (interval > 1) {
        return interval + ' años'
      }
      interval = Math.floor(seconds / 2592000)
      if (interval > 1) {
        return interval + ' meses'
      }
      interval = Math.floor(seconds / 86400)
      if (interval > 1) {
        return interval + ' días'
      }
      interval = Math.floor(seconds / 3600)
      if (interval > 1) {
        return interval + ' horas'
      }
      interval = Math.floor(seconds / 60)
      if (interval > 1) {
        return interval + ' minutos'
      }
      return Math.floor(seconds) + ' segundos'
    },
    getDist(latitude, longitude) {
      let dist = this.$getDist(
        latitude,
        longitude,
        localStorage.getItem('latitude'),
        localStorage.getItem('longitude')
      );
      return dist;
    },
  },
}
</script>

<style scoped>
.alert-card {
  margin: 15px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}
.buttons-container {
  text-align: right;
}
</style>