import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/console',
    name: 'console',
    component: () => import('../views/Console.vue')
  },
  {
    path: '/alert/:id',
    name: 'alert',
    component: () => import('../views/AlertDetails.vue')
  },
  {
    path: '/user/:user',
    name: 'user',
    component: () => import('../views/VerifyUser.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== '/' && !localStorage.getItem('token')) {
    next('/')
  } else {
    if (to.name === 'console' && localStorage.getItem('role') > '1') {
      next('home')
    } else {
      next()
    }
  }
})

export default router
