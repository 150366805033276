import Vue from "vue";

//Global Variables
Vue.prototype.$global = {
  mapOptions: {
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: false,
    rotateControl: true,
    fullscreenControl: false,
    disableDefaultUi: false
  },
  botAlertApi: 'https://api.telegram.org/bot5381080259:AAGlM-IIvbCRccohRCZ1dxy0DZzeQywy2Kc/',
  chatAdminsId: -653224536,
}

// Global Functions
Vue.prototype.$showToast = function (message, type) {
  this.$toast(message, {
    type: type,
    position: "top-right",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: true,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false
  });
}
Vue.prototype.$getAuthorization = () => {
  return 'Bearer ' + localStorage.getItem('token');
}
Vue.prototype.$getDist = (lat1, lng1, lat2, lng2) => {
  const rad = function (x) {
    return x * Math.PI / 180;
  }

  var R = 6378.137;//Radio de la tierra en km
  var dLat = rad(lat2 - lat1);
  var dLng = rad(lng2 - lng1);
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(lat1)) * Math.cos(rad(lat2)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d.toFixed(3);//Retorna tres decimales
}