<template>
  <div class="app-bar">
    <nav class="navbar navbar-expand-lg navbar-dark">
      <router-link class="navbar-brand" to="/home">
        <img src="@/assets/logo.png" width="30" height="30" class="d-inline-block align-top" alt="logo">
        <span class="ml-3">Zona Segura</span>
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav mr-auto">
          <router-link to="/home" active-class="active" class="nav-item">
            <a class="nav-link">Home</a>
          </router-link>
          <router-link v-if="user.role<2" to="/console" active-class="active" class="nav-item">
            <a class="nav-link">Consola</a>
          </router-link>
        </ul>
        <span class="navbar-text mr-2">{{usersConecteds}}</span>
        <form class="form-inline">
          <div class="dropdown">
            <button class="btn btn-dark dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Hola {{user.name}}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <button class="dropdown-item" @click="logout">Cerrar sesión</button>
            </div>
          </div>
        </form>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'AppBar',
  data() {
    return {
      user: {
        name: '',
        role: ''
      },
      usersConecteds: 0
    }
  },
  sockets: {
    usersCount(count) {
      this.usersConecteds = count
    }
  },
  methods: {
    initUserData() {
      this.user.name = localStorage.getItem('name');
      this.user.role = localStorage.getItem('role');
    },
    logout() {
      localStorage.removeItem('token')
      this.$router.replace('/')
    },
  },
  mounted() {
    this.initUserData();
  },
}
</script>

<style scoped>
nav {
  background-color: #0C7489;
}
a .dropdown-item {
  cursor: pointer;
}
</style>
