<template>
  <div id="app">
    <AppBar v-if="isAppBarVisible" />
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      isAppBarVisible: true,
    }
  },
  sockets: {
    alertRecived(data) {
      if (localStorage.getItem('token')) {
        const sownd = new Audio('./raw/Jasper.mp3');
        sownd.play();
        const curp = data[0];
        const date = data[1];
        this.$showToast(`Nueva alerta recibida de ${curp}`, 'warning');
      }
    }
  },
  updated() {
    if (this.$route.path == '/') {
      this.isAppBarVisible = false;
    } else {
      this.isAppBarVisible = true;
    }
  },
}
</script>