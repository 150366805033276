import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCryptojs from 'vue-cryptojs'
import './plugins/vue-toastification'
import './plugins/global-components'
import './plugins/global-vars'
import './plugins/vue2-google-maps.js'
import './plugins/vue-socket.io.js'

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(VueCryptojs)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
